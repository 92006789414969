import utils from 'utils';
import store from 'utils/store';
import { initAlamoUi } from 'utils/uiManager';
import resize from 'utils/resize';
import lazyLoadSetup from 'utils/lazyLoad';
import { fetchCurrentSession } from 'actions/session';
import { getAgeRangeDefaults } from 'actions/bookingWidget';
import { ModelManager, ModelClient } from '@adobe/aem-spa-page-model-manager';
import { Iterable } from 'immutable';
import { DealsList } from 'components/DealsList';
import Hero from 'components/Hero/Hero';
import { setAppConfig } from '@ehi/global-marketing-interface/setAppConfig';

DealsList; // no-op for now
const GMI_CONFIG = utils.config.getGMIConfig();
Hero; // no-op for now
// Connect App
function initApp() {
  lazyLoadSetup();
  store.dispatch(fetchCurrentSession());
  resize.init(store);
  utils.accessibility.a11yFocusOutline();

  // Get default age range options
  store.dispatch(getAgeRangeDefaults(GMI_CONFIG.COUNTRY));

  // Get vehicles list
  // store.dispatch(getVehiclesList(GMI_CONFIG.COUNTRY));

  let modelClient = new ModelClient();
  ModelManager.initialize({
    // The `path` configuration controls what the current page views as the `model.json` URL. We update this with the
    // query string from the parent page to allow pass down of query params like the `nocache` param.
    path: utils.url.getModelJsonPathWithQueryString(),
    // This is the normal `modelClient` object config suggested by AEM SPA docs.
    modelClient,
  }).then((model) => {
    /* Below event is triggered in authoring upon component content update and in HTL template based pages replaces component DOM. Below hook destroys model client(responsible for communication between content and editor frames)and then re-initializes modelManager to insure update page model is fetched and react components are reinitialized. This blunt force approach is due to limitation of API and does not affect page performance outside authoring environment. */
    window.addEventListener('reactupdate', () => {
      // If modelClient is not destroyed modelManager operates on stale page model
      modelClient.destroy();
      modelClient = new ModelClient();
      ModelManager.initialize({ modelClient }).then((updatedModel) => {
        initAlamoUi(store, updatedModel, document.body);
      });
    });

    initAlamoUi(store, model, document.body);
  });

  window.getState = (statePath) => {
    const state = store.getState().getIn(statePath);
    return Iterable.isIterable(state) ? utils.safeToJS(state) : state;
  };
  const countryCode = utils.localization.getAkamaiCountryCode();
  const existingGmaHeaders = utils.config.getGMIConfig()?.GMA_HEADERS || {};
  setAppConfig({
    GMA_HEADERS: { ...existingGmaHeaders, cdn_country_of_residence: countryCode },
  });
}

// Initiate the app once the dom is ready
utils.dom.domReady(initApp);
